import DeviceInfo from 'react-native-device-info';
import { Platform } from 'react-native';

export const isIOS = Platform.OS === 'ios';

export const majorVersionIOS = parseInt(`${Platform.Version}`, 10);

export const isIPhoneXMore = () => {
  const VERSIONS_X = ['X', 'XS', 'XR'];
  const version = DeviceInfo.getModel().split(' ')[1];
  if (VERSIONS_X.some((ver) => ver === version) || version > 8) {
    return true;
  }
  return false;
};
