import React from 'react';
import { TouchableOpacity, Image } from 'react-native';
import BackWhiteIcon from '@images/icons/back-white.png';
import { styles } from './styles';
import { HeaderBackButton } from '@react-navigation/stack';

export const BackBtn = ({ navigation }) => {
  return (
    <TouchableOpacity
      style={styles.back}
      activeOpacity={0.8}
      onPress={() => {
        if (navigation.canGoBack()) {
          navigation.goBack();
        } else {
          navigation.replace('Main');
        }
      }}
    >
      <Image source={BackWhiteIcon} style={styles.backIcon} />
    </TouchableOpacity>
  );
};
