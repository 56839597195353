import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  button: {
    width: 260,
    height: 45,
    backgroundColor: '#4FAA9F',
    borderRadius: 50,
    color: '#fff',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonLight: {
    backgroundColor: 'transparent',
    borderColor: '#4FAA9F',
    borderWidth: 2,
  },
  disabled: {
    opacity: 0.3,
  },
  disabledLight: {
    borderColor: 'rgba(68, 138, 255, 0.25)',
  },
  text: {
    color: '#fff',
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  textLight: {
    color: '#4FAA9F',
  },
  textDisabled: {
    color: '#fff',
  },
  textDisabledLight: {
    color: 'rgba(68, 138, 255, 0.4)',
  },
  loadingIcon: {
    width: 35,
    height: 35,
    resizeMode: 'cover',
  },
  icon: {
    width: 24,
    height: 24,
  },
});
