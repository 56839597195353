export default [
  {
    id: 0,
    title: 'Блок А',
    description: 'Общие требования промышленной безопасности',
  },
  {
    id: 1,
    title: 'Блок Б1',
    description:
      'Требования промышленной безопасности в химической, нефтехимической и нефтегазоперерабатывающей промышленности',
  },
  {
    id: 2,
    title: 'Блок Б2',
    description:
      'Требования промышленной безопасности в нефтяной и газовой промышленности',
  },
  {
    id: 3,
    title: 'Блок Б3',
    description:
      'Требования промышленной безопасности в металлургической промышленности',
  },
  {
    id: 4,
    title: 'Блок Б4',
    description: 'Требования промышленной безопасности в горной промышленности',
  },
  {
    id: 5,
    title: 'Блок Б5',
    description:
      'Требования промышленной безопасности в угольной промышленности',
  },
  {
    id: 6,
    title: 'Блок Б6',
    description:
      'Требования по маркшейдерскому обеспечению безопасного ведения горных работ',
  },
  {
    id: 7,
    title: 'Блок Б7',
    description:
      'Требования промышленной безопасности на объектах газораспределения и газопотребления',
  },
  {
    id: 8,
    title: 'Блок Б8',
    description:
      'Требования промышленной безопасности к оборудованию, работающему под давлением',
  },
  {
    id: 9,
    title: 'Блок Б9',
    description: 'Требования промышленной безопасности к подъемным сооружениям',
  },
  {
    id: 10,
    title: 'Блок Б10',
    description:
      'Требования промышленной безопасности при транспортировании опасных веществ',
  },
  {
    id: 11,
    title: 'Блок Б11',
    description:
      'Требования промышленной безопасности на объектах хранения и переработки растительного сырья',
  },
  {
    id: 12,
    title: 'Блок Б12',
    description:
      'Требования промышленной безопасности, относящиеся к взрывным работам',
  },
  {
    id: 13,
    title: 'Блок Г1',
    description: 'Требования к порядку работы в электроустановках потребителей',
  },
  {
    id: 14,
    title: 'Блок Г2',
    description: 'Требования к эксплуатации электрических станций и сетей',
  },
];
