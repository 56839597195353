import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  root: {
    backgroundColor: '#FFFFFF',
    flex: 1,
    paddingTop: 0,
  },
  body: {
    marginTop: 0,
    // flex: 1,
  },
  testContainer: {
    backgroundColor: '#F6F6F6',
    marginTop: 15,
    borderRadius: 8,
    padding: 25,
    alignSelf: 'center',
  },
  titleAbsolute: {
    position: 'absolute',
    top: 25,
    left: 25,
    right: 25,
    bottom: 25,
  },
  titleTest: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#000000',
  },

  titleDetail: {
    fontSize: 14,
    fontWeight: 'normal',
  },

  settingBlock: {
    marginBottom: 30,
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
  },
  settingBlockActive: {
    borderRadius: 100,
    backgroundColor: '#448AFF',
  },
  iconWrapper: {
    borderRadius: 100,
    borderWidth: 2,
    borderColor: '#448AFF',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    width: 80,
    height: 80,
    backgroundColor: '#FFFFFF',
    marginRight: 15,
  },
  iconWrapperNoDoc: {
    opacity: 0.5,
  },
  settingText: {
    flex: 1,
  },
  settingTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: 20,
  },
  openSettings: {
    width: 40,
    height: 40,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    borderRadius: 100,
    backgroundColor: '#448AFF',
  },
  settingStatus: {
    marginTop: 8,
    fontSize: 12,
    lineHeight: 15,
    fontWeight: 'bold',
    fontStyle: 'italic',
  },
  statusYes: {
    color: '#43A047',
  },
  statusNo: {
    color: '#D52B1E',
  },
  settingsActiveBtns: {
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'center',
  },
  settingsActiveBtn: {
    width: 40,
    height: 40,
    backgroundColor: '#FFFFFF',
    borderRadius: 100,
    marginHorizontal: 10,
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  settingsActiveBtnDisabled: {
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
  },
});
