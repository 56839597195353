import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { selectSendMessageState } from '@selectors/messages';

import * as actions from '@actions';

export const useSendMessage = () => {
  const sendMessageState = useSelector(selectSendMessageState);

  const dispatch = useDispatch();
  const { sendMessage } = bindActionCreators(
    {
      sendMessage: actions.sendMessage,
    },
    dispatch,
  );

  return [sendMessage, sendMessageState];
};
