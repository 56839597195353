import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  root: {
    flex: 1,
    backgroundColor: '#FFFFFF',
    //alignItems: 'center',
  },
  filter: {
    width: '100%',
    paddingVertical: 10,
    flex: 1,
  },
  filterBtn: {
    width: '100%',
    borderColor: '#448AFF',
    borderWidth: 2,
    borderRadius: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    overflow: 'hidden',
  },
  filterText: {
    color: '#448AFF',
    fontSize: 14,
    fontWeight: '700',
    paddingVertical: 5,
    paddingHorizontal: 12,
  },
  filterIcon: {
    width: 30,
    backgroundColor: '#448AFF',
    marginRight: -1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  filterIconImage: {
    width: 8,
    height: 5,
    resizeMode: 'contain',
  },
  list: {
    flex: 1,
    borderColor: '#E0E0E0',
    borderTopWidth: 1,
  },
  flatlist: {
    paddingVertical: 20,
  },
  card: {
    marginVertical: 10,
  },

  filterModal: {
    flex: 1,
  },
  filterValuesList: {
    paddingVertical: 20,
  },
  fitlerValue: {
    fontSize: 26,
    lineHeight: 34,
    paddingVertical: 8,
  },
  activeFilterValue: {
    fontWeight: 'bold',
  },
  body: {
    paddingTop: 30,
    paddingHorizontal: 20,
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
  },
  cardContainer: {
    //flex: 1,
    height: 120,
    margin: 10,
    minWidth: 260,
    //width: '100%',
  },
  cardFlex: {
    flex: 1,
  },
  maxWidth: {
    width: '100%',
  },
  testContainer: {
    minWidth: 220,
  },
  iconContainer: {
    marginLeft: 20,
  },
  titleTest: {
    fontSize: 22,
    fontWeight: 'normal',
    color: '#FFFFFF',
  },
  titleOrder: {
    color: '#000000',
  },
  titleCheck: {
    fontSize: 16,
    fontWeight: 'normal',
  },
});
