import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  root: {
    // flex: 1,
    backgroundColor: '#FFFFFF',
    // paddingTop: 120,
  },
  mainContainer: {
    flex: 1,
    justifyContent: 'space-between',
  },
  headerContainer: {
    height: 120,
  },
  titleContainer: {
    minHeight: 90,
    padding: 15,
    backgroundColor: '#fff',
  },
  contentContainer: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: '#BDBDBD',
  },
  emptyContentContainer: {
    paddingTop: 40,
    borderTopWidth: 0,
    borderBottomWidth: 0,
    borderColor: '#BDBDBD',
  },

  delimiter: {
    marginHorizontal: 0,
    height: 1,
    backgroundColor: '#BDBDBD',
  },
  swipeRowBack: {
    backgroundColor: '#8BC645',
    flexDirection: 'row',
    flex: 1,
  },
  backText: {
    color: '#FFF',
    padding: 10,
  },
  buttonContainer: {
    flexDirection: 'row',
    alignSelf: 'center',
  },
  btn: {
    // width: '90%',
    marginTop: 20,
    marginBottom: 20,
    alignSelf: 'center',
  },
  delete: {
    borderWidth: 0,
    // marginBottom: 0,
  },
  backBtn: {
    flex: 1,
    justifyContent: 'center',
  },
  backBtnLeft: {
    alignItems: 'flex-start',
    backgroundColor: 'green',
  },
  backBtnRight: {
    alignItems: 'flex-end',
    backgroundColor: 'red',
  },
  titleEmpty: {
    textAlign: 'center',
    color: '#BDBDBD',
  },
  image: {
    height: 30,
    width: 30,
  },
});
