import React from 'react';
import { View, TouchableOpacity } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { styles } from './styles';

export const CardLinearTemplate = ({
  children,
  onPress,
  colors = ['#4c669f', 'red'],
  style = {},
  styleContainer = {},
  ...props
}) => (
  <LinearGradient colors={colors} style={[styles.linearCard, style]}>
    <TouchableOpacity
      onPress={onPress}
      {...props}
      style={[styles.container, styleContainer]}
    >
      {children}
    </TouchableOpacity>
  </LinearGradient>
);
