import React, { useState } from 'react';

import { FeedbackModal, FeedbackThanksModal } from '@components/modals';

export const Feedback = () => {
  const [modalFeedbackVisible, setModalFeedbackVisible] = useState(false);
  const [modalFeedbackThanksVisible, setModalFeedbackThanksVisible] =
    useState(false);

  const onSuccessFeedback = () => {
    setModalFeedbackVisible(false);
    setModalFeedbackThanksVisible(true);
  };

  return (
    <>
      <FeedbackThanksModal
        visible={modalFeedbackThanksVisible}
        onClose={() => setModalFeedbackThanksVisible(false)}
      />
      <FeedbackModal
        visible={modalFeedbackVisible}
        onClose={() => setModalFeedbackVisible(false)}
        onSuccess={onSuccessFeedback}
      />
    </>
  );
};

export const FeedbackScreen = () => null;
