import { StyleSheet, Platform } from 'react-native';

const styleBody = Platform.select({
  ios: { flex: 1 },
  android: { minHeight: '100%', flex: 1 },
});

export const styles = StyleSheet.create({
  modalRootStyles: {
    alignItems: 'stretch',
  },
  body: {
    ...styleBody,
    justifyContent: 'center',
  },
  modalContainer: {
    ...Platform.select({
      ios: {},
      android: {},
      default: {
        maxWidth: 350,
        width: '100%',
      },
    }),
    marginTop: 25,
    marginBottom: 25,
    padding: 25,
    paddingHorizontal: 15,
    borderRadius: 8,
    backgroundColor: '#FFFFFF',
  },
  title: {
    marginBottom: 18,
  },
  text: {
    textAlign: 'center',
    fontWeight: '500',
    fontSize: 16,
    lineHeight: 19,
    color: '#666666',
  },
  btn: {
    marginTop: 28,
    marginBottom: 15,
    width: '100%',
  },
});
