import React from 'react';
import { ScrollView, View, Text, TouchableOpacity } from 'react-native';
import { GridTemplate } from '@components/templates';

import theme from '@const/theme';
import ArrowButton from '@images/icons/arrowButton.svg';
import { styles } from './styles';

export const Select = ({
  datalist,
  getValue,
  getTitle,
  defaultTitle,
  filter,
  setFilter,
  resetFilter,
  isOpenedFilterList,
  toggleFilterList,
  containerStyle,
}) => {
  return (
    <View style={[styles.filter, containerStyle]}>
      <TouchableOpacity
        style={[styles.filterBtn, styles.container]}
        activeOpacity={0.8}
        onPress={() => toggleFilterList(!isOpenedFilterList)}
      >
        {filter === null ? (
          <Text style={styles.filterText}>{defaultTitle}</Text>
        ) : (
          <Text style={styles.filterText}>
            {getTitle(datalist.find((item) => getValue(item) === filter))}
          </Text>
        )}
        <View
          style={[
            isOpenedFilterList
              ? {
                  transform: [{ rotate: '180deg' }],
                }
              : {},
          ]}
        >
          <ArrowButton />
        </View>
      </TouchableOpacity>
      {isOpenedFilterList && (
        <View style={styles.filterModal}>
          <ScrollView
            indicatorStyle={theme.indicatorStyle}
            style={styles.filterModalScroll}
            contentContainerStyle={styles.filterValuesList}
          >
            <TouchableOpacity
              activeOpacity={0.8}
              onPress={() => {
                resetFilter();
                toggleFilterList(false);
              }}
            >
              <GridTemplate>
                <Text style={[styles.fitlerValue]}>{defaultTitle}</Text>
              </GridTemplate>
            </TouchableOpacity>
            {datalist.map((item, index) => (
              <TouchableOpacity
                key={index}
                activeOpacity={0.8}
                onPress={() => {
                  toggleFilterList(false);
                  setFilter(getValue(item));
                }}
              >
                <GridTemplate>
                  <Text style={[styles.fitlerValue]}>{item.title}</Text>
                </GridTemplate>
              </TouchableOpacity>
            ))}
          </ScrollView>
        </View>
      )}
    </View>
  );
};
