import React, { useState, useEffect } from 'react';
import { Text } from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import messaging from '@react-native-firebase/messaging';
import { updateProfile, createUserWithEmailAndPassword } from 'firebase/auth';
import { Input, Button } from '@components/atoms';
import { Politics, ButtonsSocial } from '@components/organisms';
import { AuthTemplate } from '@components/templates';
import * as actions from '@actions';
import translations from '@translations/ru.json';
import { styles } from './styles';

const texts = translations.authScreen;

const onChangeField =
  (field, error, changeFunction, clearFunction) => (value) => {
    if (error) {
      clearFunction('');
    }

    if (field === 'email' && value.length > 2 && value.endsWith(' ')) {
      changeFunction(value.slice(0, -1));
    } else {
      changeFunction(value);
    }
  };

const isValidForm = ({ email }) => {
  let isValid = true;
  if (!/.+@.+\..+/i.test(email)) {
    isValid = false;
  }

  return isValid;
};

const initialPhoneInfo = {
  dialCode: '',
  isVerified: false,
  phoneNumber: '',
  unmaskedPhoneNumber: '',
  code: '',
};

const getPhone = ({ dialCode, phoneNumber }) => `${dialCode} ${phoneNumber}`;

const errors = {
  'auth/user-not-found': 'пользователь не найден',
  'auth/invalid-email': 'некорректный email',
  'auth/wrong-password': 'неверный пароль',
  'auth/too-many-requests': 'слишком много запросов, попробуйти позже',
  'auth/network-request-failed': 'технические проблемы, попробуйте позже',
  'auth/weak-password': 'пароль слишком лёгкий',
  'auth/email-already-in-use': 'email уже используется',
};

const SignUpView = ({
  signUpState,
  clearSignUpError,
  addUserData,
  signUp,
  navigation,
}) => {
  const [name, changeName] = useState('');
  const [phoneInfo, changePhoneInfo] = useState(initialPhoneInfo);
  const [email, changeEmail] = useState('');
  const [password, changePassword] = useState('');
  const phone = getPhone(phoneInfo);
  const { isVerified: isValidPhone } = phoneInfo;
  const [error, setSignUpError] = useState('');
  const [isFetching, setFetching] = useState(false);

  /*useEffect(
    () => console.log('name', name, 'email', email, 'phone', phoneInfo),
    [name, phoneInfo, email],
  );*/

  return (
    <AuthTemplate>
      <Input
        customStyle={[styles.input]}
        value={name}
        onChangeText={changeName}
        placeholder={texts.name}
        error={null}
      />
      <Input
        customStyle={[styles.input]}
        value={email}
        onChangeText={onChangeField(
          'email',
          error,
          changeEmail,
          setSignUpError,
        )}
        icon="email"
        placeholder={texts.email}
        dataDetectorTypes="address"
        keyboardType="email-address"
        textContentType="emailAddress"
        error={null}
      />
      <Input
        customStyle={[styles.input, styles.inputMargin]}
        value={password}
        onChangeText={onChangeField(
          'password',
          error,
          changePassword,
          setSignUpError,
        )}
        error={error}
        placeholder={texts.password}
        type="password"
        secureTextEntry
      />
      <ButtonsSocial style={styles.buttonsSocial} />
      <Politics style={styles.politics} />

      <Button
        customStyles={styles.btn}
        title={texts.signUp}
        isFetching={isFetching}
        onPress={() => {
          if (!isValidForm({ email })) {
            console.log('not Valid!');
            return;
          }
          setFetching(true);

          createUserWithEmailAndPassword(auth, email, password)
            .then(() => {
              // console.log('User account created & signed in!');
              if (name) {
                const update = {
                  displayName: name,
                };
                return updateProfile(auth.currentUser, update).then(() => {
                  const { displayName } = auth.currentUser.providerData[0];
                  addUserData({ displayName });
                  console.log(
                    'User account updated, displayName: ',
                    displayName,
                  );
                });
              }
              return;
            })
            .then(() => {
              setFetching(false);
            })
            .catch((err) => {
              setFetching(false);
              setSignUpError(errors[err.code]);
              console.log('error->', err);
            });
        }}
        disabled={!email || !password}
      />
    </AuthTemplate>
  );
};

const mapStateToProps = ({ signUpState }) => ({
  signUpState: { errors: [] },
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearSignUpError: actions.clearSignUpError,
      setSignUpError: actions.setSignUpError,
      addUserData: actions.addUserData,
    },
    dispatch,
  );

export const SignUp = connect(mapStateToProps, mapDispatchToProps)(SignUpView);
