import { createAction } from 'redux-actions';
import { instance, createApiActions } from '../api';

export const actionsSendMessage = createApiActions('SEND_MESSAGE');

export const sendMessage = createAction(
  'SEND_MESSAGE',
  ({ name, phone, comment, expertiseType }, callback = () => {}) => ({
    isPromise: true,
    actions: actionsSendMessage,
    promise: () =>
      instance.post('api/message', {
        name,
        phone,
        comment,
        expertise_type: expertiseType,
      }),
    callback,
  }),
);
