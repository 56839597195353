import { createAction } from 'redux-actions';
import { instancePayment as instance, createApiActions } from '../api';

export const actionsCreatePayment = createApiActions('CREATE_PAYMENT');

export const createPayment = createAction(
  'CREATE_PAYMENT',
  (data, callback = () => {}) => ({
    isPromise: true,
    actions: actionsCreatePayment,
    promise: () => instance.post('/mobile/payments', data),
    callback,
  }),
);

export const createPayment2 = createAction(
  'CREATE_PAYMENT',
  (data, callback = () => {}) => ({
    isPromise: true,
    actions: actionsCreatePayment,
    promise: () => instance.post('/widget/payments', data),
    callback,
  }),
);
