import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'space-around',
    //  backgroundColor: 'orange',
    //  height: 800,
  },
  logo: {
    alignSelf: 'center',
    // marginBottom: 60,
    // flex: 1,
  },
  base: {
    height: 190,
    width: 190,
  },
  titleContainer: {
    paddingHorizontal: 15,
    // flex: 1,
  },
  name: {
    fontSize: 28,
    fontWeight: 'bold',
    color: '#FFFFFF',
    textAlign: 'center',
  },
  title: {
    paddingTop: 0,
    fontSize: 18,
    fontWeight: 'bold',
    // textTransform: 'uppercase',
    color: '#FFFFFF',
    textAlign: 'center',
  },
  titleFor: {
    paddingTop: 20,
    fontSize: 14,
    fontWeight: 'normal',
    // textTransform: 'uppercase',
    color: '#FFFFFF',
    textAlign: 'center',
  },
  btns: {
    // flex: 1,
    alignItems: 'center',
  },
  btn: {
    minWidth: 200,
    marginBottom: 10,
  },
  btnSignUp: {
    minWidth: 200,
    borderColor: '#4FAA9F',
  },
});
