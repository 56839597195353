import { handleActions, handleAction } from 'redux-actions';

export const favourites = handleActions(
  {
    SET_FAVOURITES: (state, action) => {
      const { payload } = action;
      const { code } = payload;
      const arr = state.filter((item) => !(item.code === code));
      return arr.length == state.length ? [...state, payload] : arr;
    },
    DELETE_FAVOURITES: (state, action) => {
      return [];
    },
  },
  [],
);
