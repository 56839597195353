import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  root: {
    backgroundColor: '#FFFFFF',
    flex: 1,
    paddingTop: 0,
  },
  body: {
    marginTop: 0,
    // flex: 1,
  },
  scrollViewContainer: {
    paddingBottom: 25,
  },
  testContainer: {
    backgroundColor: '#F6F6F6',
    marginTop: 15,
    borderRadius: 8,
    padding: 25,
    // width: 400,
    // flex: 1,
    alignSelf: 'center',
  },
  gradientContainer: {
    borderRadius: 8,
    padding: 5,
    // width: 400,
    // flex: 1,
    alignSelf: 'center',
  },
  titleTest: {
    fontSize: 22,
    fontWeight: 'bold',
    color: '#00000',
  },
  titleFavoriteTest: {
    fontSize: 22,
    fontWeight: 'bold',
    color: '#FFFFFF',
  },
  titleDetail: {
    fontSize: 16,
    fontWeight: 'normal',
  },
});
