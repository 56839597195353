import React from 'react';
import { View, Text, TouchableWithoutFeedback } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import translations from '@translations/ru.json';
import { styles } from './styles';

const texts = translations.common;

export const DeleteInstruction = ({ style }) => {
  const navigation = useNavigation();

  return (
    <View style={[styles.container, style]}>
      <Text style={styles.politics}>
        {texts.instruction.one}
        {'\n'}
        <TouchableWithoutFeedback
          onPress={() => navigation.navigate('DeleteAccount')}
        >
          <Text style={styles.link}>{texts.instruction.two}</Text>
        </TouchableWithoutFeedback>
        .
      </Text>
    </View>
  );
};
