import { handleActions } from 'redux-actions';
import { actionsSendMessage } from '@actions';
import translations from '@translations/ru.json';

const texts = translations.authScreen;

const initialStateSendMessage = {
  isFetching: false,
  errors: {},
};

export const sendMessageState = handleActions(
  {
    [actionsSendMessage.request](state) {
      return {
        ...state,
        isFetching: true,
        errors: {},
      };
    },
    [actionsSendMessage.success](state) {
      return {
        ...state,
        isFetching: false,
        errors: {},
      };
    },
    [actionsSendMessage.failure](state, action) {
      const { response = {} } = action.error;

      if (response.status === 422) {
        const { data: { errors = {} } = {} } = response;

        return {
          ...state,
          isFetching: false,
          errors,
        };
      }

      return {
        ...state,
        isFetching: false,
        errors: {
          default: texts.errors.default,
        },
      };
    },
  },
  initialStateSendMessage,
);
