import React from 'react';
import { Text, StyleSheet } from 'react-native';
import DeviceInfo from 'react-native-device-info';

const version = DeviceInfo.getVersion();

export const styles = StyleSheet.create({
  text: {
    textAlign: 'center',
    marginTop: 5,
    marginBottom: 20,
  },
});

export const AppVersion = ({ style }) => (
  <Text style={[styles.text, style]}>v.{version}</Text>
);
