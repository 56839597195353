import React, { useState } from 'react';
import { View, Alert, Platform } from 'react-native';

import { Input, Button } from '@components/atoms';
import { styles } from './styles';
import { useSendMessage } from './useSendMessage';
import translations from '@translations/ru.json';

const texts = translations;

export const FeedbackForm = ({ onSuccess, theme }) => {
  const [name, changeName] = useState('');
  const [phone, changePhone] = useState('');
  const [message, changeMessage] = useState('');

  const [sendMessage, sendMessageState] = useSendMessage();

  return (
    <>
      <View style={styles.inputContainer}>
        <Input
          customStyle={styles.input}
          value={name}
          onChangeText={changeName}
          placeholder={texts.order.name}
          error={null}
        />
        <Input
          customStyle={styles.input}
          value={phone}
          onChangeText={changePhone}
          placeholder={texts.order.phone}
          error={null}
        />
        <Input
          customStyle={styles.inputMessage}
          customStyleInput={styles.inputMessage}
          value={message}
          onChangeText={changeMessage}
          placeholder={texts.order.message}
          multiline
          error={null}
        />
      </View>
      <Button
        onPress={() => {
          sendMessage(
            { name, phone, comment: message, expertiseType: theme },
            (isSuccess, response) => {
              if (isSuccess && response.data.success) {
                onSuccess();
              } else {
                if (Platform === 'ios' || Platform === 'android') {
                  Alert.alert(texts.order.error.title, texts.order.error.text, [
                    {
                      text: 'OK',
                    },
                  ]);
                } else {
                  alert(`${texts.order.error.title} ${texts.order.error.text}`);
                }
              }
            },
          );
        }}
        title={texts.order.send}
        disabled={!phone || !message}
        customStyles={styles.btn}
        isFetching={sendMessageState.isFetching}
      />
    </>
  );
};
