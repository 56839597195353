import React from 'react';
import { View, Text, Modal } from 'react-native';

import { ModalTemplate } from '@components/templates';
import { ModalCloseIcon, ModalTitle, Button } from '@components/atoms';
import { styles } from './styles';
import translations from '@translations/ru.json';

const texts = translations.more.feedback.thanks;

export const FeedbackThanksModal = ({ visible, onClose }) => {
  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={visible}
      statusBarTranslucent
      onRequestClose={onClose}
    >
      <ModalTemplate>
        <View style={styles.modalContainer}>
          <ModalCloseIcon onClose={onClose} />

          <ModalTitle style={styles.title}>{texts.title}</ModalTitle>

          <Text style={styles.text}>{texts.text}</Text>

          <Button
            onPress={onClose}
            title={texts.close}
            customStyles={styles.btn}
          />
        </View>
      </ModalTemplate>
    </Modal>
  );
};
