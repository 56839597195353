import React from 'react';
import { View, Text, StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  warning: {
    textAlign: 'center',
    paddingBottom: 2,
    paddingHorizontal: 15,
    color: 'red',
  },
  addActualQuestionsCall: {
    textAlign: 'center',
    marginVertical: 10,
  },
});

export const DeprecatedFavouritesWarning = ({
  addActualQuestionsCall,
  containerStyle,
}) => (
  <View style={containerStyle}>
    <Text style={styles.warning}>Устаревшие вопросы исключены из теста!</Text>
    {addActualQuestionsCall && (
      <Text style={styles.addActualQuestionsCall}>
        Добавьте в Избранное актуальные вопросы.
      </Text>
    )}
  </View>
);
