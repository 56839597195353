import React from 'react';
import { View, Modal } from 'react-native';

import { ModalTemplate } from '@components/templates';
import { ModalTitle, ModalCloseIcon } from '@components/atoms';
import modalTheme from '@const/theme';
import translations from '@translations/ru.json';
import { FeedbackForm } from './form';
import { styles } from './styles';

const texts = translations;

export const FeedbackModal = ({
  visible,
  onClose,
  onSuccess,
  theme = modalTheme,
}) => {
  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={visible}
      statusBarTranslucent
      onRequestClose={onClose}
    >
      <ModalTemplate>
        <View style={styles.modalContainer}>
          <ModalCloseIcon onClose={onClose} />
          <ModalTitle style={styles.title}>
            {texts.more.feedback.title}
          </ModalTitle>
          <FeedbackForm onSuccess={onSuccess} theme={theme} />
        </View>
      </ModalTemplate>
    </Modal>
  );
};
