import React from 'react';
import { Image } from 'react-native';
import { styles } from './styles';
 
export default function Home({ color = '#BDBDBD' }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 21.4556V11.4556H13V21.4556M1 8.45557L10 1.45557L19 8.45557V19.4556C19 19.986 18.7893 20.4947 18.4142 20.8698C18.0391 21.2449 17.5304 21.4556 17 21.4556H3C2.46957 21.4556 1.96086 21.2449 1.58579 20.8698C1.21071 20.4947 1 19.986 1 19.4556V8.45557Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
