import { useState, useCallback } from 'react';
import { NativeModules } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import { createPayment, createPayment2 } from '@actions';
import { selectUser } from '@selectors/user';
import { isIOS } from '@lib/deviceInfo';

export const shopId = '314220';
export const clientApplicationKey =
  'live_MzE0MjIwzpGrKbT63nCXFzBm-BXUOzzwIRdA0VL28sc';
const { YooKassa } = NativeModules;

export const usePayment = () => {
  const [amount, setAmount] = useState('');
  const [amountError, setAmountError] = useState('');

  const [satisfaction, setSatisfaction] = useState('');
  const [comment, setComment] = useState('');

  const user = useSelector(selectUser);
  const userPhoneNumber = user?.phoneNumber;

  const [isFetching, setIsFetching] = useState(false);

  const [visiblePaymentSuccessModal, setVisiblePaymentSuccessModal] =
    useState(false);
  const [isPaymentMethodSberbankExternal, setIsPaymentMethodSberbankExternal] =
    useState(false);
  const [visiblePaymentWidgetModal, setVisiblePaymentWidgetModal] =
    useState(false);
  const [confirmationToken, setConfirmationToken] = useState('');
  const resetModal = useCallback(() => {
    setVisiblePaymentSuccessModal(false);
    setIsPaymentMethodSberbankExternal(false);
  }, []);

  const [errorText, setErrorText] = useState('');

  const reset = () => {
    setAmount('');
    setAmountError('');
    setSatisfaction('');
    setComment('');
    setIsFetching(false);
    resetModal();
    setErrorText('');
  };
  const resetAndOpenSuccessModal = (isIosBankCardPayment) => {
    reset();
    if (isIosBankCardPayment) {
      setTimeout(() => {
        setVisiblePaymentSuccessModal(true);
      }, 500);
    } else {
      setVisiblePaymentSuccessModal(true);
    }
  };

  const dispatch = useDispatch();

  const onPressPayment = useCallback(() => {
    YooKassa.pay(
      amount,
      'Благодарность за работу приложения',
      'Средства пойдут на поддержку разработчиков "Промышленная Безопасность Экз".',
      clientApplicationKey,
      shopId,
      userPhoneNumber || '+7XXXXXXXXXX',
    )
      .then((res) => {
        setIsFetching(true);
        dispatch(
          createPayment(
            {
              amountValue: amount,
              token: res.token,
              metadata: {
                user_id: user?.uid || '',
                user_email: user?.email || user?.providerData[0]?.email || '',
                user_name: user?.displayName || '',
                user_phone: user?.phoneNumber || '',
                platform: isIOS ? 'iOS' : 'Android',
              },
              feedback: {
                satisfaction,
                comment,
              },
              paymentMethodType: res.paymentMethodType,
            },
            (isSuccess, result) => {
              setIsFetching(false);
              if (isSuccess) {
                const { data } = result;

                if (data.status === 'pending') {
                  const confirmationUrl = data.confirmation.confirmation_url;

                  if (res.paymentMethodType.toUpperCase() !== 'SBERBANK') {
                    YooKassa.needsConfirmPayment(confirmationUrl)
                      .then(() => {
                        resetAndOpenSuccessModal(isIOS ? true : false);
                      })
                      .catch((e) => {
                        console.error(
                          'Bank card confirmation failed: ',
                          e.message,
                        );
                        setErrorText(
                          'При подтверждении оплаты картой произошла ошибка: ' +
                            e.message,
                        );
                      });
                  } else {
                    if (data.confirmation.type === 'external') {
                      resetAndOpenSuccessModal();
                      setIsPaymentMethodSberbankExternal(true);
                    } else {
                      YooKassa.startConfirmSberPay(confirmationUrl)
                        .then(() => {
                          resetAndOpenSuccessModal();
                        })
                        .catch((e) => {
                          console.error(
                            'SberPay confirmation failed: ',
                            e.message,
                          );
                          setErrorText(
                            'При подтверждении оплаты через сбербанк произошла ошибка: ' +
                              e.message,
                          );
                        });
                    }
                  }
                } else {
                  resetAndOpenSuccessModal();
                }
              } else {
                let errorTextMessage;
                if (result.response) {
                  // The request was made and the server responded with a status code
                  // that falls out of the range of 2xx
                  errorTextMessage = result.response.data;
                } else if (result.request) {
                  // The request was made but no response was received
                  // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                  // http.ClientRequest in node.js
                  errorTextMessage = result.request;
                } else {
                  // Something happened in setting up the request that triggered an Error
                  errorTextMessage = result.message;
                }
                console.error('Payment api server error = ', errorTextMessage);
                setErrorText('Произошла ошибка на сервере API.');
              }
            },
          ),
        );
      })
      .catch((e) => {
        setIsFetching(false);
        console.error(e);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount, satisfaction, comment, userPhoneNumber, dispatch]);

  const onPressPaymentWidget = useCallback(() => {
    setIsFetching(true);
    // setVisiblePaymentWidgetModal(true);
    dispatch(
      createPayment2(
        {
          amountValue: amount,
          //token: res.token,
          metadata: {
            user_id: user?.uid || '',
            user_email: user?.email || user?.providerData[0]?.email || '',
            user_name: user?.displayName || '',
            user_phone: user?.phoneNumber || '',
            platform: isIOS ? 'iOS' : 'Android',
          },
          feedback: {
            satisfaction,
            comment,
          },
          //paymentMethodType: res.paymentMethodType,
        },
        (isSuccess, result) => {
          setIsFetching(false);
          //console.log(JSON.stringify(result, null, 2));
          if (isSuccess) {
            const {
              data: {
                confirmation: { confirmation_token },
              },
            } = result;
            // console.log(JSON.stringify(result, null, 2));
            setConfirmationToken(confirmation_token);
            setVisiblePaymentWidgetModal(true);
            /*
            if (data.status === 'pending') {
              const confirmationUrl = data.confirmation.confirmation_url;

              if (res.paymentMethodType.toUpperCase() !== 'SBERBANK') {
                YooKassa.needsConfirmPayment(confirmationUrl)
                  .then(() => {
                    resetAndOpenSuccessModal(isIOS ? true : false);
                  })
                  .catch((e) => {
                    console.error('Bank card confirmation failed: ', e.message);
                    setErrorText(
                      'При подтверждении оплаты картой произошла ошибка: ' +
                        e.message,
                    );
                  });
              } else {
                if (data.confirmation.type === 'external') {
                  resetAndOpenSuccessModal();
                  setIsPaymentMethodSberbankExternal(true);
                } else {
                  YooKassa.startConfirmSberPay(confirmationUrl)
                    .then(() => {
                      resetAndOpenSuccessModal();
                    })
                    .catch((e) => {
                      console.error('SberPay confirmation failed: ', e.message);
                      setErrorText(
                        'При подтверждении оплаты через сбербанк произошла ошибка: ' +
                          e.message,
                      );
                    });
                }
              }
            } else {
              resetAndOpenSuccessModal();
            } */
          } else {
            setConfirmationToken('');
            let errorTextMessage;
            if (result.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              errorTextMessage = result.response.data;
            } else if (result.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              errorTextMessage = result.request;
            } else {
              // Something happened in setting up the request that triggered an Error
              errorTextMessage = result.message;
            }
            console.error('Payment api server error = ', errorTextMessage);
            setErrorText('Произошла ошибка на сервере API.');
          }
        },
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount, satisfaction, comment, userPhoneNumber, dispatch]);

  return {
    amount,
    setAmount,
    amountError,
    setAmountError,
    satisfaction,
    setSatisfaction,
    comment,
    setComment,
    isFetching,
    onPressPayment,
    onPressPaymentWidget,
    visiblePaymentSuccessModal,
    visiblePaymentWidgetModal,
    setVisiblePaymentWidgetModal,
    isPaymentMethodSberbankExternal,
    resetModal,
    errorText,
    setErrorText,
    confirmationToken,
    setConfirmationToken,
  };
};
