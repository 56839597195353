import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 30,
    textTransform: 'uppercase',
  },
  input: {
    width: '100%',
    maxWidth: 400,
    marginBottom: 0,
  },
  inputMargin: {
    marginBottom: 15,
  },
  politics: {
    marginBottom: 20,
  },
  btn: {
    width: '100%',
    maxWidth: 400,
    marginBottom: 10,
  },
  btnSignUp: {
    marginBottom: 20,
    borderWidth: 0,
  },
  buttonsSocial: {
    marginBottom: 15,
  },
  forgot: {
    fontSize: 14,
    lineHeight: 20,
    textAlign: 'center',
  },
  toReset: {
    fontWeight: 'bold',
    textDecorationLine: 'underline',
  },
  titleButton: {
    color: '#fff',
  },
});
