import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  filter: {
    width: '100%',
  },
  filterBtn: {
    borderColor: '#F3F3F3',
    borderWidth: 2,
    borderRadius: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    overflow: 'hidden',
  },
  container: {
    borderWidth: 2,
    borderColor: '#D2D2D2',
    borderRadius: 20,
    height: 45,
    paddingLeft: 20,
    paddingRight: 10,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
  },
  filterText: {
    fontSize: 18,
    paddingVertical: 5,
    paddingHorizontal: 12,
  },
  filterModal: {
    height: 300,
    borderWidth: 2,
    borderColor: '#D2D2D2',
    borderRadius: 20,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderTopWidth: 0,
    marginTop: -15,
    paddingTop: 15,
    paddingBottom: 15,
  },
  filterModalScroll: {
    height: '100%',
  },
  filterValuesList: {
    paddingVertical: 0,
    paddingHorizontal: 15,
  },
  fitlerValue: {
    fontSize: 18,
    lineHeight: 30,
    paddingVertical: 8,
  },
});
