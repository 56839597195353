import React, { useState } from 'react';

import { View } from 'react-native';
import { DrawerContentScrollView, DrawerItem } from '@react-navigation/drawer';
import {
  CommonActions,
  DrawerActions,
  useLinkBuilder,
} from '@react-navigation/native';

import { FeedbackModal, FeedbackThanksModal } from '@components/modals';
import Logo from '@components/atoms/icons/Logo';

import { styles } from './styles';

const FeedbackDrawerItem = ({ label, icon }) => {
  const [modalFeedbackVisible, setModalFeedbackVisible] = useState(false);
  const [modalFeedbackThanksVisible, setModalFeedbackThanksVisible] =
    useState(false);

  const onSuccessFeedback = () => {
    setModalFeedbackVisible(false);
    setModalFeedbackThanksVisible(true);
  };

  const onPress = () => setModalFeedbackVisible(true);

  return (
    <>
      <FeedbackThanksModal
        visible={modalFeedbackThanksVisible}
        onClose={() => setModalFeedbackThanksVisible(false)}
      />
      <FeedbackModal
        visible={modalFeedbackVisible}
        onClose={() => setModalFeedbackVisible(false)}
        onSuccess={onSuccessFeedback}
      />
      <DrawerItem label={label} icon={icon} onPress={onPress} />
    </>
  );
};

const CustomDrawerItemList = ({ state, navigation, descriptors }) => {
  const buildLink = useLinkBuilder();

  const focusedRoute = state.routes[state.index];
  const focusedDescriptor = descriptors[focusedRoute.key];
  const focusedOptions = focusedDescriptor.options;

  const {
    drawerActiveTintColor,
    drawerInactiveTintColor,
    drawerActiveBackgroundColor,
    drawerInactiveBackgroundColor,
  } = focusedOptions;

  return state.routes.map((route, i) => {
    const focused = i === state.index;

    const onPress = () => {
      const event = navigation.emit({
        type: 'drawerItemPress',
        target: route.key,
        canPreventDefault: true,
      });

      if (!event.defaultPrevented) {
        navigation.dispatch({
          ...(focused
            ? DrawerActions.closeDrawer()
            : CommonActions.navigate({ name: route.name, merge: true })),
          target: state.key,
        });
      }
    };

    const {
      title,
      drawerLabel,
      drawerIcon,
      drawerLabelStyle,
      drawerItemStyle,
      drawerAllowFontScaling,
    } = descriptors[route.key].options;

    if (route.name === 'Feedback') {
      return <FeedbackDrawerItem label={drawerLabel} icon={drawerIcon} />;
    }

    return (
      <DrawerItem
        key={route.key}
        label={
          drawerLabel !== undefined
            ? drawerLabel
            : title !== undefined
            ? title
            : route.name
        }
        icon={drawerIcon}
        focused={focused}
        activeTintColor={drawerActiveTintColor}
        inactiveTintColor={drawerInactiveTintColor}
        activeBackgroundColor={drawerActiveBackgroundColor}
        inactiveBackgroundColor={drawerInactiveBackgroundColor}
        allowFontScaling={drawerAllowFontScaling}
        labelStyle={drawerLabelStyle}
        style={drawerItemStyle}
        to={buildLink(route.name, route.params)}
        onPress={onPress}
      />
    );
  });
};

export const CustomDrawerContent = ({ isMobile, ...props }) => {
  return (
    <DrawerContentScrollView {...props}>
      {!isMobile && (
        <View style={styles.logo}>
          <Logo />
        </View>
      )}
      <CustomDrawerItemList {...props} />
    </DrawerContentScrollView>
  );
};
