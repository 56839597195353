import { handleActions, handleAction } from 'redux-actions';

const initStateUser = { uid: null, progress: { success: 0, total: 0 } };

/*
export const user = handleAction(
  'SET_USER',
  (state, action) =>
    action?.payload ? { ...state, ...action?.payload?._user } : initStateUser,
  initStateUser,
);
*/

export const user = handleActions(
  {
    SET_USER: (state, action) => {
      // console.log('action=>', action.payload);
      return action?.payload
        ? { ...state, ...action?.payload}
          // { ...state, ...action?.payload }
        : initStateUser;
    },

    ADD_USER_DATA: (state, action) =>
      action?.payload ? { ...state, ...action?.payload } : state,
  },
  initStateUser,
);
