import React, { useRef } from 'react';
import { FlatList, View, Text } from 'react-native';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { FindQuestion } from '@components/organisms';
import * as actions from '@actions';
import blocks from '@data/blocks.js';
import tests from '@data/tests';
import Checked from '@images/icons/check.svg';
import { styles } from './styles';
import { Select } from '@components/atoms';
import { useFilter } from './useFilter';

const FindView = ({ fontSize }) => {
  const scrollRef = useRef();

  const {
    results,
    findQuestion,
    setFindQuestion,
    filterBlock,
    isOpenedFilterBlockList,
    toggleFilterBlockList,
    getBlockValue,
    getBlockTitle,
    filterTest,
    setFilterTest,
    resetFilterTest,
    isOpenedFilterTestList,
    toggleFilterTestList,
    getTestValue,
    getTestTitle,
    setFilterBlockFn,
    resetFilterBlock,
  } = useFilter();

  const testsForBlock = tests[filterBlock] || [];

  const Card = React.memo(({ item }) => (
    <View style={styles.testContainer}>
      <Text style={[styles.titleDetail, { fontSize }]}>
        {item.testTitle && (
          <Text style={[styles.titleTest, { fontSize }]}>
            {item.testTitle}{' '}
          </Text>
        )}
        {/* перестраховка от дубля номера вопроса */}
        {item?.text?.[0] !== '(' && `(${item.questionNumber})${'  '}`}
        {item.text}
      </Text>
      {item.correctAnswers.map((answer, index) => (
        <View style={styles.answersContainer} key={index}>
          <View style={{ flex: 1, alignItems: 'center' }}>
            <Checked />
          </View>
          <View style={{ flex: 6 }}>
            <Text style={{ fontSize }}>{answer}</Text>
          </View>
        </View>
      ))}
    </View>
  ));

  const renderItem = ({ item }) => <Card item={item} />;

  return (
    <>
      <FindQuestion
        style={styles.search}
        value={findQuestion}
        handleChangeText={setFindQuestion}
        resetValue={() => setFindQuestion('')}
      />
      <Select
        datalist={blocks}
        getValue={getBlockValue}
        getTitle={getBlockTitle}
        defaultTitle="Все блоки"
        filter={filterBlock}
        setFilter={setFilterBlockFn}
        isOpenedFilterList={isOpenedFilterBlockList}
        toggleFilterList={toggleFilterBlockList}
        resetFilter={resetFilterBlock}
        containerStyle={styles.filter}
      />

      {filterBlock !== null && (
        <Select
          datalist={testsForBlock}
          getValue={getTestValue}
          getTitle={getTestTitle}
          defaultTitle="Все тесты"
          filter={filterTest}
          setFilter={setFilterTest}
          isOpenedFilterList={isOpenedFilterTestList}
          toggleFilterList={toggleFilterTestList}
          resetFilter={resetFilterTest}
          containerStyle={styles.filter}
        />
      )}
      <FlatList
        ref={scrollRef}
        data={results}
        renderItem={renderItem}
        initialNumToRender={5}
        maxToRenderPerBatch={5}
        removeClippedSubviews={true}
        windowSize={5}
        /* getItemLayout={(data, index) => ({
          length: 80,
          offset: 80 * index,
          index,
        })} */
        showsVerticalScrollIndicator={false}
        updateCellsBatchingPeriod={100}
        style={styles.resultList}
      />
    </>
  );
};

const mapStateToProps = ({ results, settings: { fontSize } }) => ({
  testResults: results,
  fontSize,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetResults: actions.resetResults,
    },
    dispatch,
  );

export const FindViewContent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(FindView);
