import { handleActions, handleAction } from 'redux-actions';

export const results = handleActions(
  {
    SET_RESULTS: (state, action) => {
      const { payload } = action;
      return { ...state, ...payload };
    },
    RESET_RESULTS: (state, action) => {
      const { payload } = action;
      const code = payload;
      return { ...state, [code]: [] };
    },
  },
  {},
);
