import { handleActions } from 'redux-actions';

import { showDonate, hideDonate, hideDonateForever } from '../actions/donate';

export const mustShowDonate = handleActions(
  {
    [showDonate]: () => true,
    [hideDonate]: () => false,
  },
  false,
);

export const mustHideDonateForever = handleActions(
  {
    [hideDonateForever]: () => true,
  },
  false,
);
