import { createAction } from 'redux-actions';
import { instance, createApiActions } from '../api';
import auth from '@react-native-firebase/auth';

export const setUser = createAction('SET_USER');

export const addUserData = createAction('ADD_USER_DATA');

export const resetData = createAction('RESET_DATA');
/*export const actionsRestorePassword = createApiActions('RESTORE_PASSWORD');
export const clearRestorePasswordError = createAction(
  'RESTORE_PASSWORD_CLEAR_ERROR',
);
export const setRestorePasswordError = createAction(
  'RESTORE_PASSWORD_SET_ERROR',
);

export const restorePassword = createAction(
  'RESTORE_PASSWORD',
  ({ email }, callback = () => {}) => ({
    isPromise: true,
    actions: actionsRestorePassword,
    promise: () => instance.post('user/password', { email }),
    callback,
  }),
);
*/
