import React from 'react';
import { AppRegistry, Platform } from 'react-native';
import { RootSiblingParent } from 'react-native-root-siblings';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import App from './src/App';
import { name as appName } from './app.json';
import configureStore from './src/store';
import 'react-native-console-time-polyfill';

function HeadlessCheck({ isHeadless }) {
  if (Platform.OS === 'ios' && isHeadless) {
    // App has been launched in the background by iOS, ignore
    return null;
  }

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <RootSiblingParent>
          <App />
          {/*<FirstScreen />*/}
        </RootSiblingParent>
      </PersistGate>
    </Provider>
  );
}

const { store, persistor } = configureStore();

AppRegistry.registerComponent(appName, () => HeadlessCheck);

AppRegistry.runApplication(appName, {
  rootTag: document.getElementById('react-native-web-app'),
});
