import React, { useState, useEffect } from 'react';
import { ImageBackground, TouchableOpacity, View, Text } from 'react-native';
import { connect } from 'react-redux';
import { useWindowDimensions } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import imageProfile from '@images/profile-header.png';
import Apple from '@images/icons/apple.svg';
import Menu from '@images/icons/menu.svg';
import Arrow from '@images/icons/arrow.svg';
import { styles } from './styles';

const HeaderTest = ({
  style,
  leftTitles = [],
  rightComponent = null,
  //navigation,
}) => {
  const navigation = useNavigation();

  const { width } = useWindowDimensions();

  const [isMobile, setIsMobile] = useState(width < 1070);

  useEffect(() => {
    setIsMobile(width < 1070);
  }, [width]);

  useEffect(() => {
    // console.log('state->', navigation);
  }, [navigation]);

  return (
    <View
      style={[
        style,
        {
          height: 30,
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: 15,
          // backgroundColor: 'pink',
        },
        isMobile ? { alignSelf: 'stretch' } : { alignSelf: 'flex-start' },
      ]}
    >
      <View>
        <TouchableOpacity
          style={{
            // height: 40,
            // backgroundColor: 'yellow',
            alignItems: 'center',
            justifyContent: 'center',
            paddingHorizontal: isMobile ? 5 : 20,
            flexDirection: 'row',
          }}
          onPress={() => {
            if (navigation.canGoBack()) {
              navigation.goBack();
            } else {
              navigation.jumpTo('MainMenu');
            }
          }}
        >
          {leftTitles.map((item, i) => (
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Arrow />
              <Text
                style={{
                  fontSize: isMobile ? 16 : 20,
                  marginHorizontal: 10,
                  lineHeight: 20,
                  fontWeight:
                    leftTitles.length == i + 1 && leftTitles.length !== 1
                      ? 'bold'
                      : 'normal',
                }}
              >
                {item}
              </Text>
            </View>
          ))}
        </TouchableOpacity>
      </View>
      <View
        style={{
          marginLeft: 20,
          // minHeight: 15,
          // minWidth: 20,
          // backgroundColor: 'orange',
        }}
      >
        {rightComponent}
      </View>
    </View>
  );
};

const mapStateToProps = ({ user }) => ({
  user,
  isLogin: !!user.id,
  displayName: user.displayName,
});

export const HeaderTestFlow = connect(mapStateToProps)(HeaderTest);
