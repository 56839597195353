import { handleActions, handleAction } from 'redux-actions';

export const settings = handleAction(
  'SET_SETTINGS',
  (state, action) => {
    const { payload } = action;
    return { ...state, ...payload };
  },
  { fontSize: 14, mixAnswers: false },
);
