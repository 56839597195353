import { StyleSheet, Platform } from 'react-native';

export const styles = StyleSheet.create({
  inputContainer: {
    width: '100%',
    alignSelf: 'stretch',
    marginTop: 10,
    marginBottom: 10,
  },
  input: {
    marginTop: 0,
    marginBottom: 0,
  },
  inputMessage: {
    height: 185,
    fontSize: 16,
    ...Platform.select({
      ios: {},
      android: {},
      default: {
        paddingTop: 10,
      },
    }),
  },
  btn: {
    marginTop: 20,
    marginBottom: 15,
    width: '100%',
    ...Platform.select({
      ios: {},
      android: {},
      default: {
        maxWidth: 350,
      },
    }),
  },
});
