import React from 'react';
import { View, Text, Modal, TouchableOpacity } from 'react-native';
import { ModalTemplate } from '@components/templates';
import { Button } from '@components/atoms';
import { s } from './styles';
import Close from '@components/atoms/icons/Close';
import Failure from '@components/atoms/icons/Failure';
import Success from '@components/atoms/icons/Success';

export const Final = ({
  visible,
  onToggle,
  onPressBack,
  onPressAgain,
  mode,
  titleTest = '',
  description = '',
  status,
}) => {
  const content = {
    exam: {
      success: {
        title: 'Поздравляем!',
        detail: 'Вы успешно\nпрошли экзамен',
      },
      failure: {
        title: 'Вы не сдали',
        detail:
          'При прохождении теста вы сделали больше 2-х ошибок. Попробуйте еще раз.',
      },
    },
    fixErrors: {
      success: {
        title: 'Поздравляем!',
        detail: 'Вы успешно провели\nработу над ошибками',
      },
      failure: {
        title: 'Вы не прошли\nработу над ошибками',
        detail: 'Попробуйте еще раз',
      },
    },
    test: {
      success: {
        title: 'Поздравляем!',
        detail: `Тест ${titleTest}\n«${description}»\nпройден.`,
      },
    },
    favourites: {
      success: {
        title: 'Поздравляем!',
        detail: 'Треновка по избранным вопросам прошла успешно!',
      },
      failure: {
        title: 'Вы не прошли\nтест',
        detail: 'Попробуйте еще раз',
      },
    },
  };

  const { title, detail } = content[mode][status];
  const Icon = status === 'success' ? Success : Failure;

  return (
    <Modal
      statusBarTranslucent
      animationType="fade"
      transparent={true}
      visible={visible}
    >
      <ModalTemplate>
        <View style={s.modalContainer}>
          <TouchableOpacity onPress={() => onToggle()} style={s.icon}>
            <Close />
          </TouchableOpacity>
          {(mode == 'exam' || 'fixErrors') && <Icon />}
          <Text style={s.title}>{title}</Text>
          <Text style={s.detail}>{detail}</Text>
          <View style={s.btns}>
            {!(mode == 'fixErrors' && status == 'success') && (
              <Button
                onPress={() => {
                  onPressAgain();
                }}
                title="Пройти заново"
                customStyles={s.btn}
              />
            )}
            <Button
              onPress={onPressBack}
              title="Вернуться на главную"
              customStyles={s.btn}
            />
          </View>
        </View>
      </ModalTemplate>
    </Modal>
  );
};
